body {
    overflow-y: auto;
    overflow-x: hidden;
    
}
.header {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 0;   
    z-index: 1;
}
.entire {
    min-height: 100vh;
}
.sticky-top {
    position: sticky;
    position: -webkit-sticky;
}
.mainH1 {
    font-family: 'Rock Salt', cursive;
    color: #556b2f;
}
h2, h3 {
    color: #556b2f;
} 
a > text {
    text-decoration: none !important;
    color: #556b2f;
}
.mainPage {
    width: 100%;
    height: 100vh;
    -webkit-transform: translateZ( 0 );
    transform: translateZ( 0 );
    -webkit-transition: -webkit-transform 0.8s ease-in-out;
    transition: transform 0.8s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  
}
.nav {
    width: 100%;
    background-color: #FBEEC1;
    height: 30px;
    
}
.navbar {
    width: 100% !important;
    background-color: #FBEEC1;
}
.navbar-brand {
    margin-right: 0;
}

.nav-item {
    margin-right: 20px;
    text-align: center;
    padding-top: 0;
}

.nav-item > .nav-link {
    color: #8D8741;
    font-size: 1.25rem;
    font-family: 'Varela Round', sans-serif;
    padding-top: 0px;
}
.nav-link {
    color: #8D8741;
    font-size: 1rem;
    font-family: 'Varela Round', sans-serif;
    padding-top: 0px;
}
.navDrop {
    color: #8D8741 !important;
    font-size: 1rem;
    font-family: 'Varela Round', sans-serif;
    padding-top: 0px;
    text-align: center !important;
}
.nav-link:hover {
    color: #BC986A;
}
.nav-link:active {
    color: gray !important;
}
.middle {
    width: 100%;
    margin: auto;
    min-height: calc(100vh - 100px);
    background-color: #FBEEC1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23a6c275' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
.imageContainer {
    background-color: #FBEEC1;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.First {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding-bottom: 10px;
}
.First > p {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.firstPic {
    max-width: 100%;
}
.milkCheese {
    display: block;
    max-width: 30%;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.verticalCheese {
    border-radius: 25px;
}

.Dairy, .History, .Products, .Map {
    max-width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding-bottom: 10px;
}
.Products {
    z-index: 0 !important;
}
.flexImg {
    flex: auto;
}
.swede {
    max-width: 300px;
}
.location {
    font-size: 1.5rem;
    font-weight: bolder;
    color: #556b2f;
    cursor: pointer;
}
.footer {
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    color:#8D8741;
    text-align: center;
    font-weight: 400;
    background-color: #FBEEC1;
    font-weight: lighter;
}
.i, .fab, .fa, .fas {
    color: #8D8741;
}



@media only screen and (max-width: 600px) { 
    .milkCheese {
        max-width: 80%;
    }
}